import React from 'react'
import {GrInstagram} from 'react-icons/gr'

const ContactSocials = () => {
  return (
    <div className='contact_socials'>
        <a href="https://www.instagram.com/lululearning_/" target='_blank' rel="noreferrer"><GrInstagram size={20}/> </a>

    </div>
  )
}

export default ContactSocials