import React from 'react'
import './contact.css'
import LOGO from '../../assets/LogoFamily_FullLogo_Black.png'
import ContactSocials from './ContactSocials'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5ui514f', 'template_o7wn3h2', form.current, '7iKYPWIhtDRrKK5dp')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };
  return (
    <section id='contact'>

      <ContactSocials/> 
      <img src={LOGO} className alt="comapany logo"/>

      <div className='contact_words'>
        <h5>For inquiries please connect</h5>
        <h5>with me by sending a message below.</h5>
      </div>

      <div className='wrapper'>
        <form ref={form} onSubmit={sendEmail}>
        <div>
           <input type="text" name='name' placeholder='name' className='form_entry' required />
           <input type="email" name='email' placeholder='email' className='form_entry' required />
           </div>
           <div>
           <textarea name='message' rows="2" placeholder='message' className='form_entry'></textarea>
           </div>
           <button type='submit' className='btn btn-primary'>Send</button>
          
        </form>
      </div>
    
    </section>
  )
}

export default Contact